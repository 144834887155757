<template>
  <div class="multipleTable">
    <el-table
        empty-text="暂无数据"
        ref="table"
        :data="tableData"
        row-key="id"
        stripe
        fit
        highlight-current-row
        :border="border"
        size="medium"
        class="tipleTable"
        :default-expand-all='expand'
        :tree-props="{children: 'children',hasChildren: 'hasChildren'}"
        :expand-row-keys="expands"
        @selection-change="handleSelectionChange"
        @row-click="handleRowChange"
        @sort-change="handleSort"
        @filter-change="filterHandler"
        @expand-change="expandChange"
        >
        <!-- 选择框 -->
        <el-table-column
          v-if="selectionType"
          type="selection"
          width="55"
          align="center" />
        <template v-for="(itm, idx) in tableHeader">
        <!-- 特殊处理列 -->
        <el-table-column
            v-if="itm.render"
            :key="idx"
            :prop="itm.prop ? itm.prop : null"
            :label="itm.label ? itm.label : null"
            :width="itm.width ? itm.width : null"
            :sortable="itm.sortable ? itm.sortable : false"
            :align="itm.center ? itm.center : 'center'"
            :fixed="itm.fixed ? itm.fixed : null"
            :show-overflow-tooltip="itm.tooltip"
            min-width="50"
        >
            <template slot-scope="scope">
            <ex-slot
                :render="itm.render"
                :row="scope.row"
                :index="scope.$index"
                :column="itm"
            />
            </template>
        </el-table-column>
        <!-- 正常列 -->
        <el-table-column
            v-else
            :key="idx"
            :prop="itm.prop ? itm.prop : null"
            :label="itm.label ? itm.label : null"
            :width="itm.width ? itm.width : null"
            :sortable="itm.sortable ? itm.sortable : false"
            :align="itm.center ? itm.center : 'center'"
            :fixed="itm.fixed ? itm.fixed : null"
            :formatter="itm.formatter"
            :show-overflow-tooltip="itm.tooltip"
            min-width="50"
        />
        </template>
    </el-table>
    <div class="pagination-container" v-if="hideSinglePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizeList" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :page="page">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  render: (h, context) => {
    const params = {
      row: context.props.row,
      index: context.props.index
    };
    if (context.props.column) params.column = context.props.column;
    return context.props.render(h, params);
  }
};

export default {
  name: 'comp-table',
  components: { exSlot },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    tableHeader: {
      type: Array,
      default: () => []
    },
    selectionType: {
      type: Boolean,
      default: () => false
    },
   multipleSelection: {
     type: Array,
     default: function () {
       return []
     }
   },
   page: {  // 分页 当前页数
     type: Number,
     default: function () {
       return 1
     }
   },
   pageSize: { // 分页 当前条数
     type: Number,
     default: function () {
       return 10
     }
   },
   total: { // 分页总数
     type: Number,
     default: function () {
       return 20
     }
   },
   expand: {
     type: Boolean,
     default: function () {
       return true
     }
   },
   border: {
     type: Boolean,
     default: function () {
       return true
     }
   },
   hideSinglePage: {
     type: Boolean,
     default: function () {
       return true
     }
   },
   pageSizeList: {
     type: Array,
     default: function () {
       return [20, 50, 100, 200]
     }
   },
   height: {
     type: String,
     default: '99%'
   }

  },
  data() {
    return {
      expands: [],
      current: this.page,
      inTableHeight: null,
    };
  },
  created() {
    //该阶段可以接收父组件的传递参数
    this.inTableHeight = this.height;
  },
  mounted() {
    this.$nextTick(() => {
      //表格高度自适应浏览器大小
      this.changeTableHight();
      if (!this.height) {
        window.onresize = () => {
          this.changeTableHight();
        };
      }
    });
  },
  destroyed() {
    //高度自适应事件注销
    window.onresize = null;
  },
  watch: {
    /**
     * 数据变化后 高度自适应
     */
    tableList() {
      this.$nextTick(() => {
        this.changeTableHight();
      });
    }
  },
  methods: {
      /**
       * 点击事件
       */
      handleCurrentChange(Current) {
        this.current = Current;
        this.$emit("save-current", Current);
        this.$emit("current-events", Current);
      },
      handleSelectionChange(val) {
        console.log(val)
        this.$emit('multipleSelection', val)
      },
      handleSort(sort) {
        this.$emit('sort-events', sort)
      },
      filterHandler(filters) {
        this.$emit('filter-events', filters)
      },
      handleSizeChange(size) { //表格分页size改变
        this.$emit('size-events', size)
      },
      expandChange(row) {
        let temp = this.expands;
        this.expands = [];
        this.expands.push(row.id);
        if (temp.length === 1 && temp[0] === row.id) {
          // 收起展开行
          this.expands = [];
        }
      },
      handleRowChange(row, column, event) {
        this.$emit('row-click', row, column, event)
      },

    /**
     * 高度自适应
     * 当表格展示空间小于460按460px展示，大于的时候高度填充
     */
    changeTableHight() {
      if (this.height) {
        //如果有传进来高度就取消自适应
        this.inTableHeight = this.height;
        this.$refs.table.doLayout();
        return;
      }
      let tableHeight = window.innerHeight || document.body.clientHeight;
      //高度设置
      let disTop = this.$refs.table.$el;
      //如果表格上方有元素则减去这些高度适应窗口，66是底下留白部分
      tableHeight -= disTop.offsetTop + 66;
      if (disTop.offsetParent) tableHeight -= disTop.offsetParent.offsetTop;
      this.inTableHeight = tableHeight < 460 ? 460 : tableHeight;
      //重绘表格
      this.$refs.table.doLayout();
    }
  }
};
</script>
<style lang="less" scoped>
  .multipleTable {
    height: 99%;
    position: relative;
  }
  .tipleTable {
    min-height: 91.5%;
    border: 1px solid #ebeef5;
    height: calc(100% - 2.82rem);
    overflow: auto;
  }
  .pagination-container {
    position: absolute;
    right: 24px;
  }
  .el-pagination{
    display: inline-block;
    margin-top: 0.83rem;
  }
  .btn{
    margin-right: 10px !important;
  }
  </style>
