import request from '@/utils/request'

// 五险一金列表
export function insurancesList(params) {
  return request({
    url: '/api/salary/staff/insurances',
    method: 'get',
    params
  })
}

// 删除五险一金
export function deleteInsurances(params) {
  return request({
    url: '/api/salary/staff/insurances',
    method: 'delete',
    params
  })
}

// 新增/编辑五险一金
export function editInsurances(data, methods) {
  return request({
    url: '/api/salary/staff/insurances',
    method: methods,
    data
  })
}

// 新增五险一金
// export function editInsurances(data) {
//   return request({
//     url: '/api/salary/staff/insurances',
//     method: 'put',
//     data
//   })
// }