<template>
  <div class="app-container">
     <nav>
      <el-form :inline="true" :model="queryForm" ref="queryForm" class="demo-form-inline">
        <el-form-item label="购买方名称">
          <el-input v-model="queryForm.buyerName" placeholder="请输入查询条件"></el-input>
        </el-form-item>
        <el-form-item label="开票日期">
          <el-date-picker
            v-model="queryForm.invoiceTime"
            type="date"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开票金额">
          <el-input v-model="queryForm.invoiceAmount" placeholder="请输入查询条件"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search"  @click="handleQuery"  v-auth='"invoicingFailureStatistics:lookup"'>查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-waves class="filter-item" type="primary" icon="el-icon-refresh" @click="handleReset()"  v-auth='"invoicingFailureStatistics:lookup"'>重置</el-button>
        </el-form-item>
      </el-form>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
      <!-- <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='true'  :total='total' @current-events="initTable" @size-events='savePage' class="comp_table" v-if="tableData.length">
      </comps-table> -->
      <el-table border :data="tableData" style="width: 100%" v-if="tableData.length" class="tableCla">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="失败原因：">
                <span>{{ props.row.failReason }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <!-- 开票日期 -->
        <el-table-column label="发票类型" prop="fplxdm">
          <template slot-scope="scope">
            {{scope.row.fplxdm=='026'?'增值税电子普通发票':'增值税电子专用发票'}}
          </template>
        </el-table-column>
        <el-table-column label="购买方名称" prop="buyerName"></el-table-column>
        <el-table-column label="开票类型" prop="issueType">
          <template slot-scope="scope">
            {{scope.row.issueType=='0'?'正数发票':'负数发票'}}
          </template>
        </el-table-column>
        <el-table-column label="开票日期" prop="invoiceTime"></el-table-column>
        <el-table-column label="开票金额" prop="invoiceAmount"></el-table-column>
        <el-table-column label="状态" prop="issueStatus">
          <template slot-scope="scope">
            {{scope.row.issueStatus=='02'?'开具失败':'开具成功'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="desc" width="80" align='center'>
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="reopen(scope.row)"  v-auth='"invoicingFailureStatistics:recreate"'>重开</el-button>
          </template>
        </el-table-column>
      </el-table>
      <nodata v-else message="暂无数据" class="no_data" />
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="pagination"
        >
      </el-pagination>
    </div>

  </div>
</template>

<script>
  // import delDialog from '@/components/Dialog'
  import compsTable from "@/components/table/table";
  import { insurancesList, deleteInsurances } from '@/api/insurances'
  // import { findTerminal, findTerminalList, delTerminal } from '@/api/setInvoice'
  // import importDialog from './dialog/imports'
  // import exportDialog from './dialog/export'
  // import tipsDialog from './dialog/showTips'
  export default {
    components: {  compsTable ,},
    data() {
      return {
        queryForm: {
          buyerName:'',
          invoiceTime: '',
          invoiceAmount:'',
          page: 1,
          rows: 20
        },
        isLoading: false,
        hasData: false,
        
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],
        
        
        delDialog: false,
        importVis: false,
        exportVis:false,

        page:1,
      }
    },
    methods: {
     handleSizeChange(val) {
        this.pageSize=val;
         this.initTable()
      },
      handleCurrentChange(val) {
        this.page=val;
        this.initTable()
      },


      handleQuery(){
        this.queryForm.page = 1
        this.initTable()
      },

      //重置
      handleReset() {
        this.queryForm = {
          buyerName:'',
          invoiceTime: '',
          invoiceAmount:'',
        }
        this.$refs.queryForm.resetFields()
        this.initTable()
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      //重开
      reopen(data){
          console.log(data,'重开')
          // this.$router.push("/invoicing/electronicInvoice");
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        if(data.issueType != '0'){
            this.$router.push({
            path:'/invoicing/negativeElectronicInvoice/edit',
            query:{
              faildRecord:data.id,
              category:'1',
              reopen :'1',//重开
              // typeId: '0'+data.invoiceType, //“026”增值税电子普通发票；“028”增值税电子专用发票
              typeId: data.fplxdm=='026'?'01':'02', //“026”增值税电子普通发票；“028”增值税电子专用发票
            }
          })
        }else{
          if(data.fplxdm=='026'){
              this.$router.push({
              path:'/invoicing/electronicInvoice/ordinary',
              query:{
                faildRecord:data.id,
                category:'1',
                reopen :'1',//重开
                // typeId: '0'+data.invoiceType, //“026”增值税电子普通发票；“028”增值税电子专用发票
                typeId: data.fplxdm=='026'?'01':'02', //“026”增值税电子普通发票；“028”增值税电子专用发票
              }
            })
          }else{
             this.$router.push({
              path:'/invoicing/electronicInvoice/specialUse',
              query:{
                faildRecord:data.id,
                category:'1',
                reopen :'1',//重开
                // typeId: '0'+data.invoiceType, //“026”增值税电子普通发票；“028”增值税电子专用发票
                typeId: data.fplxdm=='026'?'01':'02', //“026”增值税电子普通发票；“028”增值税电子专用发票
              }
            })
          }
           
        }
        
      },
      // savePage(val) {
      //   this.pageSize = val
      //   this.initTable()
      // },
      // 开票失败统计列表
      initTable(val) {
        this.isLoading = true;
        this.$store.dispatch("findFail", {
          page: this.page,
          rows: this.pageSize,
          buyerName:this.queryForm.buyerName,
          invoiceTime:this.queryForm.invoiceTime,
          invoiceAmount:this.queryForm.invoiceAmount,
          companyId : this.getToken('companyId'),
          // userId: this.getToken('userId'),
          
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            // this.total = res.data.length;
            this.total = res.totalCount;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },

      
     handleExport(){
        if (this.multipleSelection.length == 0) {
          einvAlert.warning('提示','请先勾选',true,'确定')
          return;
        } 
        this.exportVis = true;
     },
      exportCancel(){
        this.exportVis = false;
      },
      
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less">
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 35px);
    padding: 24px 20px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
 .showAllCompany{
  float: right;
  padding-bottom: 10px;
 }
 .demo-form-inline{
  margin-top: 20px;
 }
 .pagination{
    text-align: right;
    margin-right: 20px;
    margin-top: 0.83rem;
 }
.tableCla{
    width: 100%;
    height: calc(100% - 3.1rem);
    overflow: auto
}
</style>
